import { url } from "@/config";
import request from "../axios.js";
//发送短信接口
export const realmSmsCode = (data) =>
  request({
    url: url + "/action/tenant/sendSmsCode",
    method: "post",
    data: data,
  });
//登录接口
export const realmlogin = (data) =>
  request({
    url: url + "/action/admin/login",
    method: "post",
    data: data,
  });
//注册接口
export const register = (data) =>
  request({
    url: url + "/action/admin/signup",
    method: "post",
    data: data,
  });
//退出登录
export const logout = (data) =>
  request({
    url: url + "/action/admin/logout",
    method: "post",
    data: data,
  });
export const getRealmlDetail = (realmlame) =>
  request({
    url: url + `/realms/${realmlame}`,
    method: "get",
  });

//获取租户信息
export const getUserInfo = (id) =>
  request({
    url: url + `/tenant/${id}`,
    method: "get",
  });
//校验短信
export const checkCode = (data) =>
  request({
    url: url + `/action/tenant/checkCode`,
    method: "post",
    data,
  });
