import Vue from "vue";
import App from "./App.vue";
import { website } from "../public/config"
Vue.prototype.$website = website
Vue.config.productionTip = false;
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
// import Avue from '@smallwei/avue';
// import '@smallwei/avue/lib/index.css';
// Vue.use(Avue);
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "./element-variables.scss";
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
