import Vue from "vue";
import router from "@/router/index";
import Vuex from "vuex";
import { AUTH_TOKEN, TENANTID } from "@/utils/constants.js";
import { getUserInfo } from "@/api/user.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    tenantId: "",
    isLogin: localStorage.getItem("AUTH_TOKEN") ? false : true,
    userInfo: {},
  },
  getters: {},
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setTenantId(state, tenantId) {
      state.tenantId = tenantId;
    },
    loginOutAction(state) {
      state.token = "";
      state.tenantId = "";
      state.userInfo = {};
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(TENANTID);
      localStorage.removeItem("userInfo");
      router.push("/layout/HomePage");
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
  },
  actions: {
    getUserInfoData(context, tenantId) {
      getUserInfo(tenantId)
        .then((res) => {
          const userInfo = res.data.data || {};
          context.commit("setUserInfo", userInfo);
        })
        .catch(() => {
          context.commit("setUserInfo", {});

        });
    },
  },
  modules: {},
});
